import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, useIsPresent } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import '../styles/pages/_contact.scss';

const Contact = () => {
  const isPresent = useIsPresent();

  return (
    <>
    <Seo title='Contact Me' />
    <Layout pageId='contact'>
      <Container>
        <Row className='contact'>
          <motion.div 
            className='col-md-6 col-sm-12'
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 1, 
              ease: [0.61, 1, 0.88, 1] 
            }}
          >
            <h1 className='contact__title'><div>COME</div><div>AND</div><div>SAY HI</div></h1>
            <Row>
              <Col md={{ span: 8, offset: 4 }} sm={12}>
                <p className='contact__copy'>Feel free to reach out if you wanna collaborate with me, or simply have a chat</p>
              </Col>
            </Row>
            <Row>
              <Col sm={3} xs={6}>
                <a href='https://www.facebook.com/mark.pacis' target='_blank' rel='noreferrer'>
                  <StaticImage src='../images/social-icons/facebook.svg' alt='Facebook' outputPixelDensities='2' />
                  <p>FACEBOOK</p>
                </a>
              </Col>
              <Col sm={3} xs={6}>
                <a href='https://twitter.com/PacManNR7' target='_blank' rel='noreferrer'>
                  <StaticImage src='../images/social-icons/twitter.svg' alt='Twitter' outputPixelDensities='2' />
                  <p>TWITTER</p>
                </a>
              </Col>
              <Col sm={3} xs={6}>
                <a href='https://instagram.com/PacManNR7' target='_blank' rel='noreferrer'>
                  <StaticImage src='../images/social-icons/instagram.svg' alt='Instagram' outputPixelDensities='2' />
                  <p>INSTAGRAM</p>
                </a>
              </Col>
              <Col sm={3} xs={6}>
                <a href='https://www.linkedin.com/in/mark-pacis-293791245' target='_blank' rel='noreferrer'>
                  <StaticImage src='../images/social-icons/linkedin.svg' alt='LinkedIn' outputPixelDensities='2' />
                  <p>LINKEDIN</p>
                </a>
              </Col>
            </Row>
          </motion.div>
          <motion.div
            className='col-md-6 col-sm-12 email'
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 1, 
              delay: 1.25, 
              ease: [0.61, 1, 0.88, 1] 
            }}
          >
            <a href='mailto:markpacis.creative@gmail.com'><h1>markpacis.creative@gmail.com</h1></a>
          </motion.div>
        </Row>
        <motion.div
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
          exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
          style={{ originY: isPresent ? 0 : 1 }}
          className='screen-page-transition'
        />
      </Container>
    </Layout>
    </>
  )
}

export default Contact